import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
//import '@mdi/font/css/materialdesignicons.css'
//import 'material-icons/iconfont/material-icons.scss';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import i18n from './i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueToasted from 'vue-toasted';

Vue.use(VueAxios, axios)
Vue.use(VueToasted, {
  theme: "toasted-primary", //['toasted-primary', 'outline', 'bubble']
  position: "top-right", //['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
  duration : 5000,
  iconPack : 'material',
  className:"gwtoasted",
  keepOnHover: true,
  fullWidth: false,
  fitToScreen: false,
  //tipe: '' //['success', 'info', 'error']
});
//Vue.config.productionTip = false
//const init = () => {
  new Vue({
    //mixins: [gw],
    router,
    store,
    vuetify,
    i18n,
    //GW,
    render: h => h(App)
  }).$mount('#app')
//   console.log("Hola DIspositivo  Android");
  
// };

// document.addEventListener("deviceready", () => {
//   init();
// });
